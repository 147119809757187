<template lang="">
  <div>
    <b-card style="margin-top: 90px">
      <div class="formulario-usuario-foto">
        <upload-foto
          @upload="(img) => (form.foto = img)"
          :fotoSrc="form.foto"
        />
      </div>
      <div class="row" style="margin-top: 90px">
        <div class="col-12">
          <h4 class="card-title mb-3">
            {{ $t(`USUARIOS.TITULO_DADOS`) }}
          </h4>
        </div>
      </div>
      <div class="row">
        <input-text
          class="col-12 col-md-4"
          :label="$t('USUARIOS.TABELA.NOME')"
          v-model="form.nome"
          ref="form-nome"
          required
        />
        <input-text
          class="col-12 col-md-4"
          :label="$t('USUARIOS.TABELA.EMAIL')"
          v-model="form.email"
          ref="form-email"
          type="email"
        />
        <input-mask
          class="col-12 col-md-2"
          :label="$t('USUARIOS.TABELA.CPF')"
          :mask="['###.###.###-##']"
          type="cpf"
          v-model="form.cpf"
          ref="form-cpf"
          required
        />
        <input-mask
          class="col-12 col-md-2"
          :label="$t('USUARIOS.TABELA.CONTATO')"
          :mask="['(##)####-####', '(##)#####-####']"
          v-model="form.numeroTelefone"
          ref="form-numeroTelefone"
          required
        />
      </div>
      <div class="mt-2">
        <h4 class="card-title mb-3">
          {{ $t(`USUARIOS.TITULO_ACESSO`) }}
        </h4>
      </div>
      <div class="row">
        <input-text
          class="col-12 col-md-4"
          :label="$t('USUARIOS.TABELA.USUARIO')"
          v-model="form.userName"
          ref="senhaForm-usuario"
          required
        />
        <div class="col-12 col-md-4">
          <input-text
            type="password"
            :label="$t('USUARIOS.TABELA.SENHA')"
            v-model="form.senha"
            @input="verificarForcaDaSenha"
            ref="senhaForm-senha"
            required
          />
          <verifica-forca-senha embaixo :value="forcaSenha" />
        </div>
        <input-text
          type="password"
          class="col-12 col-md-4"
          :label="$t('USUARIOS.TABELA.CONFIRMACAO_SENHA')"
          v-model="form.confirmacaoSenha"
          ref="senhaForm-confirma"
          required
        >
          <template #append>
            <b-button
              @click="alterarSenha"
              v-if="(id || telaMinhaConta) && permissao"
              class="ml-2"
              variant="success"
            >
              {{ $t("USUARIOS.ALTERAR_SENHA") }}
            </b-button>
          </template>
        </input-text>
      </div>

      <div class="mt-4 d-flex justify-content-end">
        <b-button
          @click="cancelar"
          variant="outline-success"
          v-if="!telaMinhaConta"
          class="mr-2"
        >
          Cancelar
        </b-button>
        <b-button @click="salvar" variant="success" v-if="permissao">
          Salvar
        </b-button>
      </div>
    </b-card>
  </div>
</template>
<script>
import { InputText, InputMask } from "@/components/inputs";
import UsuarioService from "@/common/services/usuario/usuario.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import helpers from "@/common/utils/helpers";
import cloneDeep from "lodash.clonedeep";
import VerificaForcaSenha from "@/components/outros/VerificaForcaSenha.vue";
import MinhaContaService from "../../common/services/usuario/minha-conta.service";
import uploadFoto from "@/components/uploadFoto";
export default {
  props: ["id"],
  components: {
    InputText,
    InputMask,
    VerificaForcaSenha,
    uploadFoto,
  },
  data: function () {
    return {
      form: {
        nome: "",
        foto: null,
        cpf: "",
        numeroTelefone: "",
        email: "",
        senha: "",
        userName: "",
        confirmacaoSenha: "",
      },
      forcaSenha: "",
    };
  },
  methods: {
    salvar: function () {
      this.confirmar(
        this.$t(
          `USUARIOS.TITULO_SALVAR_${this.telaMinhaConta ? "PERFIL" : "USUARIO"}`
        ),
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then((confirmado) => {
        if (!confirmado) return;

        if (
          !this.validarFormulario(this.id || this.telaMinhaConta ? "form-" : "")
        )
          return false;

        if (!this.id) {
          if (this.form.senha != this.form.confirmacaoSenha) {
            this.alertaErro(this.$t("USUARIOS.MENSAGEM_SENHA"));
            return;
          }
        }

        this.$store.dispatch(START_LOADING);
        let form = cloneDeep(this.form);
        form.cpf = helpers.removerMascara(form.cpf);
        form.numeroTelefone = helpers.removerMascara(form.numeroTelefone);
        (this.telaMinhaConta ? MinhaContaService : UsuarioService)
          .salvar(form)
          .then(() => {
            this.alertaSucesso(this.$t("USUARIOS.MENSAGEM_SUCESSO")).then(
              () => {
                if (!this.telaMinhaConta)
                  this.$router.push({ name: "usuarios" });
              }
            );
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },

    alterarSenha: function () {
      this.confirmar(
        this.$t("USUARIOS.TITULO_SENHA"),
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then((confirmado) => {
        if (!confirmado) return;

        if (!this.validarFormulario("senhaForm")) return false;

        if (this.form.senha != this.form.confirmacaoSenha) {
          this.alertaErro(this.$("USUARIOS.MENSAGEM_SENHA"));
          return;
        }

        this.$store.dispatch(START_LOADING);
        let form = {
          usuarioId: this.id,
          novaSenha: this.form.senha,
          novaConfirmacaoSenha: this.form.confirmacaoSenha,
        };
        (this.telaMinhaConta ? MinhaContaService : UsuarioService)
          .alterarSenha(form)
          .then(() => {
            this.alertaSucesso(this.$t("USUARIOS.MENSAGEM_SUCESSO_SENHA")).then(
              () => {}
            );
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },

    cancelar: function () {
      this.$router.push({ name: "usuarios" });
    },

    buscar: function () {
      this.$store.dispatch(START_LOADING);
      (this.telaMinhaConta ? MinhaContaService : UsuarioService)
        .buscar(this.id)
        .then((res) => {
          this.form = res.data.data;
          this.form.senha = "";
          this.form.confirmacaoSenha = "";
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    verificarForcaDaSenha() {
      let senha = this.form.senha;
      UsuarioService.verificarForcaDaSenha(senha).then((result) => {
        this.forcaSenha = result.data.forcaSenhaEnum;
      });
    },

    retornarPerfil: function (perfil) {
      return helpers.getEnum(perfil);
    },
  },
  mounted() {
    if (this.telaMinhaConta) {
      this.$store.dispatch(DEFINIR_BREADCRUMB, [
        { titulo: this.$t("USUARIOS.TITULO_MINHA_CONTA") },
      ]);
    } else {
      this.$store.dispatch(DEFINIR_BREADCRUMB, [
        { titulo: this.$t("USUARIOS.TITULO_LISTA"), to: "/usuarios" },
        {
          titulo: this.$t(
            `USUARIOS.TITULO_FORMULARIO_${!this.id ? "NOVO" : "EDICAO"}`
          ),
        },
      ]);
    }

    if (this.id) {
      this.buscar();
    }

    if (this.telaMinhaConta) this.buscar();
  },
  computed: {
    telaMinhaConta: function () {
      return this.$route.name == "minha-conta";
    },
    usuario: function () {
      return this.$store.state.autenticacao.usuario;
    },
    permissao: function () {
      if (this.$route.name == "minha-conta") return true;

      return helpers.validarAcesso("Usuario")[this.id ? "editar" : "criar"];
    },
  },
};
</script>
<style lang="scss">
.formulario-usuario-foto {
  position: absolute;
  margin-top: -100px;
  border-radius: 50%;
  background: #fff;

  .btn-remove {
    position: absolute;
    margin-left: 153px;
    margin-top: -1px;
    cursor: pointer;
  }
}
</style>
