<template>
  <div
    :class="
      embaixo
        ? 'ml-3'
        : !emLinha
        ? 'ml-4 mr-4 progress-container'
        : 'w-100 d-flex align-items-center mb-2'
    "
  >
    <div v-if="!embaixo" :class="!emLinha ? 'mt-2 mb-4' : 'mr-2'">
      {{ valor.texto }}
    </div>
    <b-progress
      :class="!emLinha ? 'mr-3' : 'w-50'"
      class="warning"
      :value="valor.porcentagem"
      :variant="valor.variante"
      :max="4"
    >
    </b-progress>
    <div v-if="embaixo" class="mt-1 font-12">{{ valor.texto }}</div>
  </div>
</template>

<script>
export default {
  name: "VerificaForcaSenha",
  props: {
    value: {
      type: String,
      default: "Vazio",
    },
    emLinha: {
      type: Boolean,
      default: false,
    },
    embaixo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valores: [
        { valor: "Vazio", texto: "", porcentagem: 0, variante: "danger" },
        {
          valor: "MuitoFraco",
          texto: "Senha muito fraca",
          porcentagem: 1,
          variante: "danger",
        },
        {
          valor: "Fraco",
          texto: "Senha fraca",
          porcentagem: 2,
          variante: "danger",
        },
        {
          valor: "Medio",
          texto: "Senha média",
          porcentagem: 3,
          variante: "warning",
        },
        {
          valor: "Forte",
          texto: "Senha forte",
          porcentagem: 4,
          variante: "success",
        },
        {
          valor: "MuitoForte",
          texto: "Senha muito forte",
          porcentagem: 5,
          variante: "success",
        },
      ],
    };
  },
  computed: {
    valor() {
      return this.valores.find((el) => el.valor === this.value) || {};
    },
  },
};
</script>

<style scoped>
.progress-container {
  width: 160px !important;
}
</style>
